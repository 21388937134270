import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SessionApiService } from './session-api.service';
import { SessionGuard } from './session.guard';
import { SessionResolver } from './session.resolver';
import { SessionManagerService } from './session-manager.service';

@NgModule({
  imports: [RouterModule],
  providers: [SessionApiService, SessionGuard, SessionResolver, SessionManagerService],
})
export class SessionDataAccessModule {}
