import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { SessionApiService } from './session-api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EXTENSION_DIALOG } from './tokens';
import { of, switchMap, tap } from 'rxjs';

@Injectable()
export class SessionManagerService {
  readonly destroyRef = inject(DestroyRef);
  readonly secondsLeft = signal<number>(0);
  readonly canExtendSession = signal(false);
  readonly showTimeWarning = signal(false);
  readonly #dialog = inject(MatDialog);
  #sessionApi = inject(SessionApiService);
  #extensionDialog = inject(EXTENSION_DIALOG);

  initializeSession(secondsLeft: number, hasBeenExtended: boolean) {
    this.canExtendSession.set(!hasBeenExtended);
    this.secondsLeft.set(secondsLeft);
  }

  extendTime() {
    return this.#sessionApi.extend().pipe(
      tap((session) => {
        this.secondsLeft.set(session.seconds_left);
        this.canExtendSession.set(false);
        this.showTimeWarning.set(false);
      })
    );
  }

  askForSessionExtension(): void {
    const dialogRef = this.#dialog.open(this.#extensionDialog, {
      disableClose: true,
      data: { timeLeft: this.secondsLeft() },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result) {
            return this.extendTime();
          }
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
